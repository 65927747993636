import { Box } from "@material-ui/core";
import React from "react";

import "./index.scss";

function MobilePrivacyPage() {
  return (
    <Box className="mobileprivacy-policy-page-container">
      <Box>
        <h1 className="mobileprivacy-policy-page-header">Mobile Privacy</h1>
      </Box>
      <Box className="mobileprivacy-policy-page-textBox">
        <span className="mobileprivacy-policy-page-span">
          Mobile Application Privacy Policy Our platform is designed with mobile
          traders in mind, and we want to keep your data safe while on the go.
          We use advanced SSL technology to keep your data protected within our
          network. Relax and enjoy secure trading without being tethered to your
          desk. Cronos AI Mobile Application Privacy Policy The Cronos AI
          privacy policy covers the use of the trading software application for
          mobile devices. Our software enables you to trade several hundred
          assets (currency, commodities, stocks, and indices) via an online
          interface. The app includes a trading platform, charting tools,
          constantly updated prices for the assets, as well as access to Cronos
          AI account history, including your financial and trading transactions.
          Information Collected Through User Participation The Cronos AI Trading
          software collects basic information directly from users as part of the
          download and registration process. While some portions of the
          application are available without having to register, use of most of
          the features provided by the Cronos AI trading software does require
          completion of the full registration procedure. As part of registering
          your Cronos AI trading software account with us, you will be asked to
          submit personal information such as (i) your full name, email address,
          date of birth, user ID, password, and other registration details
          included on the Cronos AI account application form, (ii)
          account-funding information, such as deposits and withdrawals, (iii)
          specifics regarding requests for assistance, (iv) credit card
          information for making financial transactions in regards to your
          trading account, and, (v) data required to access the Cronos AI
          software, such as contact information. Cronos AI may contact you
          periodically to provide you with application updates, and notices as
          required by your local jurisdiction and promotional information.
          Information Collected Automatically Along with information provided by
          users, the Cronos AI trading software may gather specific information
          automatically. This information may include any or all of the
          following: your mobile device type, the unique ID of your mobile
          device, the IP address used by your mobile device to access the Cronos
          AI trading software, the operating system on your mobile device, your
          preferred mobile Internet browsers, and additional data regarding how
          you make use of the Cronos AI trading software. This list is not
          all-inclusive, and additional information may be collected as part of
          the automatic process. GPS technology (or other similar geolocation
          systems) may be used to determine your current location, including
          your city. This may be expressed in the form of a map with relevant
          services for your area. Cronos AI will not share your current location
          with other users or partners, except as necessary to provide services
          to your account. If you decide you don’t want Cronos AI trading
          software to have access to your location, you can switch off
          geolocation settings from within your mobile phone settings. Can third
          parties gain access to information obtained by the Application? Under
          the normal conditions of use, the Cronos AI trading software does not
          share personal information with third parties unless it has been
          aggregated in a way that provides anonymity. Any information that we
          provide is clearly stated within the terms of this policy agreement.
          Cronos AI trading software commits to releasing information to third
          parties only as listed below. Information collected directly from the
          user and as part of an automatic process may be shared with third
          parties under the following circumstances: When required to comply
          with a legally executed subpoena, or as part of a similar process,
          When we determine the disclosure is necessary as part of an
          investigation of fraud, or is justified to protect the rights of
          Cronos AI or our users, When necessary as part of the job function of
          our service providers when working on our behalf, only as needed to
          complete the work required of them, and when guaranteed to remain
          securely held by only those individuals on a need to know basis as
          part of their position. In the event that Cronos AI becomes involved
          in an imminent change in ownership in the future, this information may
          be provided to prospective owners, or to companies who would be part
          of a merger or acquisition. In this case, Cronos AI will notify
          impacted users of the updated information and will provide steps as to
          how to manage access to any personal information. Your opt-out rights.
          Cronos AI holds data submitted to us by users for as long as they have
          the application installed on their mobile device, and for a reasonable
          amount of time after the application is uninstalled. Any data
          collected automatically will be held for a period of two years. At
          that point, it will be converted into an aggregated format for record
          keeping. To request removal of any user provided data from our
          records, please contact us by at support@cronos-ai.com How Data is
          Held and Maintained You can cancel access to Cronos AI trading
          software by uninstalling the application from your mobile device. This
          can be accomplished via your mobile device settings, or through the
          Cronos AI trading software. You can also email your opt-out request
          to: support@cronos-ai.com Storage of some data may be required to
          ensure proper functionality of the Cronos AI trading software. Usage
          by Minors The Cronos AI trading software is not meant for users under
          the age of majority in their respective jurisdiction. If a parent or
          guardian of a child under the age of majority believes that their
          child has in fact provided Cronos AI with personal information, he or
          she should notify us by email: support@cronos-ai.com Any information
          found in our files that relates to a child under the age of majority
          will be deleted within a reasonable time. Security We are concerned
          about maintaining the utmost care in safeguarding your personal
          information. Although we strive to protect your records, no security
          system can completely eliminate the possibility of a security breach.
          We will work with all Cronos AI employees and service providers to
          maintain the security of your records as part of the ongoing course of
          business. Updates to this Privacy Policy Cronos AI will keep you
          informed of any changes to this Privacy Policy by via online updates,
          and email or SMS notifications. This Privacy Policy page will be
          regularly updated regarding changes, and if you are in doubt about it
          at all, we advise you to check our website for the latest policy
          details. The policy as listed here constitutes the latest policy.
          Consent to Use of Information If you are using the Cronos AI trading
          software, this is deemed as your consent to the current policy. This
          privacy policy applies to all data collected as part of the standard
          use of the application, and includes, but is not limited to, all
          cookies, usage data, analytics, third-party services, system logs,
          identifying details, and records as provided by the user, or as
          collected automatically. Contact us For any additional information
          regarding the use or storage of your personal information, please
          contact us by email at: support@cronos-ai.com
        </span>
      </Box>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Box>
  );
}

export default MobilePrivacyPage;
