import { Box } from "@material-ui/core";

import "./index.scss";
import { Flip, Slide } from "react-awesome-reveal";
const AboutUsPage = () => {
  return (
    <>
      {/* <h1 className="about-us-page-header">About Us</h1> */}
      <Box className="about-us-page-container">
        <Slide duration={2000}>
          <Box className="about-us-types-container">
            <Box className="about-us-images-wrapper">
              <img src="icon-mission.svg" width={"330px"} alt="icon mission" />
            </Box>
            <Box>
              <h1 className="about-us-types-headers">
                Transforming Online Trading
              </h1>
              <span className="about-us-types-spans">
                We achieve this by offering a range of solutions tailored for
                <br></br>
                both novice traders and seasoned professionals. Education is
                paramount, <br></br>with a comprehensive library of on-demand
                seminars (V.O.D.), webinars, daily updates, market analysis, and
                more. <br></br>
                Our dedicated customer support is always available to meet your
                requirements, while our financial experts <br></br> understand
                that your success is our priority.
              </span>
            </Box>
          </Box>
        </Slide>
        <Slide duration={2000} direction="right">
          <Box className="about-us-types-container-reverse">
            <Box className="risk-text">
              <h1 className="about-us-types-headers">Risk Management</h1>
              <span className="about-us-types-spans">
                Established in 2011, Cronos AI is an investment platform that{" "}
                <br></br>
                has delivered remarkable results. Our traders and financial{" "}
                <br></br>
                experts' experience and expertise are pivotal to the fund's{" "}
                <br></br>
                success. Despite the high-risk nature of the markets our traders{" "}
                <br></br>
                navigate, our professionals employ effective strategies to{" "}
                <br></br>
                minimize risks and maximize returns. Profits generated by our{" "}
                <br></br>
                specialists are equitably shared among the fund, private
                investors, and clients.
              </span>
            </Box>
            <Box className="about-us-images-wrapper">
              <img src="icon-risk-management.svg" alt="icon risk" />
            </Box>
          </Box>
        </Slide>
        <Slide duration={2000}>
          <Box className="about-us-types-container">
            <Box className="about-us-images-wrapper">
              <img src="icon-team.svg" width={"330px"} alt="icon risk" />
            </Box>
            <Box>
              <h1 className="about-us-types-headers">
                Client Relationship Management
              </h1>
              <span className="about-us-types-spans">
                Our team of experienced Financial Analysts is dedicated to{" "}
                <br></br>
                cultivating enduring relationships with clients. Delivering{" "}
                <br></br>
                superior service and meticulous attention to detail are integral{" "}
                <br></br>
                to successfully managing your portfolio. Together, we achieve
                more.
              </span>
            </Box>
          </Box>
        </Slide>
        <Slide direction="right" duration={2000}>
          <Box className="about-us-types-container-reverse">
            <Box>
              <h1 className="about-us-types-headers">Hi-Tech Security</h1>
              <span className="about-us-types-spans">
                To thrive in the financial markets, providing a secure trading{" "}
                <br></br>
                environment is paramount. Cronos AI implements advanced security
                protocols such as <br></br> SSL Security, PCI DSS compliance,
                Comodo Certification Authority, and peer-to-peer encryption.{" "}
                <br></br>
                Your safety is our utmost priority.
              </span>
            </Box>
            <Box className="about-us-images-wrapper">
              <img src="icon-shield.svg" width={"330px"} alt="icon shield" />
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default AboutUsPage;
