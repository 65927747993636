import * as React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllTransactions } from "../../../../../../redux/transactions/transactionsSlice";
import { Box, TableSortLabel } from "@mui/material";
import EditTransactionHistory from "./editTransacionHistory";
import { transactionService } from "../../../../../../services/transactionServices";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { userServices } from "../../../../../../services/userServices";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const headCells = [
  { id: "id", label: "Id", disablePadding: false },
  { id: "createdAt", label: "Created At", disablePadding: false },
  { id: "userFullName", label: "User", disablePadding: false },
  { id: "amount", label: "Amount", disablePadding: false },
  { id: "description", label: "Description", disablePadding: false },
  { id: "beforeTransaction", label: "Before", disablePadding: false },
  { id: "afterTransaction", label: "After", disablePadding: false },
  { id: "action", label: "Action", disablePadding: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: "#3E4396" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ textWrap: "nowrap" }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ColumnGroupingTableTransactions({ user }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [transactiontoEdit, setTransactionToEdit] = React.useState(null);

  const rows = useSelector(
    (state) => state.transactions.transactions?.transactions
  );
  const [page, setPage] = React.useState(0);
  const totalCount = useSelector(
    (state) => state.transactions.transactions?.totalCount
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnRowClick = (event, transaction) => {
    setTransactionToEdit(transaction);
    setModalOpen(true);
  };

  const handleDeleteTransaction = (transaction) => {
    transactionService.deleteTransaction(transaction?.id).then(() => {
      const payload = {
        rowsPerPage,
        page: page + 1,
        ...(!!user && { userFullName: user?.firstName + " " + user?.lastName }),
      };
      dispatch(getAllTransactions(payload));
    });
  };

  React.useEffect(() => {
    const payload = {
      rowsPerPage,
      page: page + 1,
      ...(!!user && { userFullName: user?.firstName + " " + user?.lastName }),
    };
    dispatch(getAllTransactions(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, dispatch]);

  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  const rowsToShow =
    reduxUser?.role === "fake"
      ? rows?.filter(
          (row) =>
            row.userFullName ===
            reduxUser?.firstName + " " + reduxUser?.lastName
        )
      : rows;
  return (
    <div className="transactions-history-container-admin">
      {!user && (
        <h1 className="transactions-history-header">
          {!!rowsToShow ? "Transactions History" : <Skeleton />}
        </h1>
      )}
      {totalCount > 0 && rowsToShow.length > 0 ? (
        <Paper
          style={{
            width: !!user ? "95%" : "95%",
            maxHeight: "400px",
            overflowY: "auto",
            margin: !!user ? "0 auto" : "0 2% auto",
            marginBottom: !!user ? "1rem" : "0",
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 750, background: "#1F2A40" }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={reduxUser?.role === "fake" ? rowsToShow : totalCount}
              />
              <TableBody style={{ background: "#1F2A40" }}>
                {rowsToShow?.map((row, index) => {
                  return (
                    <TableRow
                      hover={true}
                      role="checkbox"
                      tabIndex={-1}
                      key={row?.id}
                      style={{ cursor: "pointer" }}
                      onDoubleClick={(e) => handleOnRowClick(e, row)}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.createdAt &&
                          new Date(+row.createdAt).toLocaleString("sv-SE", {
                            timeZone: "Europe/Stockholm",
                          })}
                      </TableCell>
                      <TableCell>{row.userFullName}</TableCell>
                      <TableCell>{row.amount}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.beforeTransaction}</TableCell>
                      <TableCell>{row.afterTransaction}</TableCell>
                      <TableCell>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteTransaction(row)}
                        >
                          <DeleteOutlineOutlined
                            style={{
                              color: "red",
                              width: "30px",
                              height: "30px",
                              marginRight: "1rem",
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ background: "#3E4396" }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={reduxUser?.role === "fake" ? rowsToShow : totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : !totalCount && window.location.href.includes("dashboard") ? (
        <div
          style={{
            width: !!user ? "95%" : "95%",
            maxHeight: "400px",
            overflowY: "auto",
            margin: !!user ? "0 auto" : "0 2% auto",
            marginBottom: !!user ? "1rem" : "0",
          }}
        >
          <Skeleton height={60} width={700} count={6} />
        </div>
      ) : reduxUser?.role === "fake" ? (
        <div
          style={{
            width: !!user ? "95%" : "95%",
            maxHeight: "400px",
            overflowY: "auto",
            margin: !!user ? "0 auto" : "0 2% auto",
            marginBottom: !!user ? "1rem" : "0",
          }}
        >
          <Skeleton height={60} width={700} count={6} />
        </div>
      ) : (
        <h3 style={{ color: "red" }}>No available transactions...</h3>
      )}
      {modalOpen && (
        <EditTransactionHistory
          user={user}
          transaction={transactiontoEdit}
          rowsPerPage={rowsPerPage}
          page={page}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
}
