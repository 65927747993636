import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { login, loginUser } from "../../redux/users/userSlice";
import { userServices } from "../../services/userServices";
import "./index.scss";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobile, isTablet } from "react-device-detect";

const LoginPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  //   React.useEffect(() => {
  //     if (!!user) {
  //       navigate("/trade", { replace: true })
  //     }
  // },[])

  const toTimeStamp = (strDate) => {
    let date = Date.parse(strDate);
    return date;
  };

  const renderDeviceType = isMobile ? "Mobile" : isTablet ? "Tablet" : "Laptop";

  const handleLogin = async (e) => {
    e.preventDefault();
    const userData = await userServices.loginUser({
      email: email,
      password: password,
      deviceType: renderDeviceType,
    });
    if (userData.success) {
      await localStorage.setItem("accessToken", userData.accessToken);
      await dispatch(login(userData));
    } else if (userData.underInvestigation) {
      toast.error(
        `Hello ${email}! Your account has been put on under investigation mode!`,
        {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: false,
          pauseOnHover: true,
          toastId: 3,
        }
      );
    } else if (userData.loginForbidden) {
      toast.error(
        `You cannot login! Please contact your financial adviser for more information!`,
        {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: false,
          pauseOnHover: true,
          toastId: 3,
        }
      );
    } else {
      setError(userData.message);
    }
  };

  return (
    <div>
      <div className="login-container">
        <div className="panel">
          {/* <h2 className="login-h2-header">Login to your account</h2> */}

          <form
            action=""
            method="post"
            className="form"
            onSubmit={(e) => handleLogin(e)}
          >
            <div className="panel-group">
              <input
                className="input"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{
                  color: "#A6A6A6",
                  backgroundColor: "#151515",
                  border: "1px solid #A6A6A6",
                }}
              />
              {/* <span className="border"></span> */}
              <label style={{ color: "#A6A6A6", marginTop: "-1%" }}>
                Email
              </label>
            </div>

            <div className="panel-group">
              <input
                className="input"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{
                  color: "#A6A6A6",
                  backgroundColor: "#151515",
                  border: "1px solid #A6A6A6",
                }}
              />
              {/* <span className="border"></span> */}
              <label style={{ color: "#A6A6A6", marginTop: "-1%" }}>
                Password
              </label>
            </div>
            <small style={{ color: "red" }}>{error}</small>
            <button type="submit">Login</button>
          </form>
          {user &&
            !user.message &&
            user.email &&
            navigate("/trade", { replace: true })}
          <div className="panel-footer-login">
            <p>
              <a
                style={{
                  cursor: "pointer",
                  fontSize: "1rem",
                }}
                onClick={() => navigate("/forgotten-password")}
              >
                Forgotten password?
              </a>
            </p>
            {/* <p style={{ fontSize: "1rem", color: "white" }}>
              Not a member?{" "}
              <a
                style={{
                  cursor: "pointer",
                  fontSize: "1rem",
                }}
                onClick={() => navigate("/sign-up")}
              >
                Start trading now!
              </a>
            </p> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginPage;
