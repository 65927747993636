import { Box } from "@material-ui/core";
import "./index.scss";
import { Slide } from "react-awesome-reveal";

const FeaturesPage = () => {
  return (
    <>
      <Box
        style={{
          marginTop: window.location.href.includes("features") ? "3rem" : "0",
        }}
        className="features-page-container"
      >
        <Box className="features-page-textBox">
          <h3 className="features-page-h3">What We Bring to the Table</h3>
          <Box className="features-page-span">
            <span>
              At Cronos AI, we’re committed to empowering our users with
              cutting-edge tools and personalized services that drive success in
              today's fast-paced markets.
            </span>
          </Box>
        </Box>

        {/* Features Section */}
        <Box className="features-types-container">
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-wallet.svg" width={"140px"} alt="wallet-icon" />
              <h2 className="features-types-headers">
                Robust Wallet Protection
              </h2>
              <span className="features-types-spans">
                Safeguard your assets with our advanced wallet protection
                system, built to ensure complete security for your funds.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-advisor.svg" width={"140px"} alt="advisor-icon" />
              <h2 className="features-types-headers">
                Expert Financial Guidance
              </h2>
              <span className="features-types-spans">
                Leverage the knowledge of a personal financial advisor who will
                provide expert insights tailored to your trading strategy.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img
                src="icon-portfolio.svg"
                width={"140px"}
                alt="portfolio-icon"
              />
              <h2 className="features-types-headers">
                Tailored Portfolio Management
              </h2>
              <span className="features-types-spans">
                Our comprehensive platform helps you manage and optimize your
                portfolio, ensuring it aligns with your long-term financial
                objectives.
              </span>
            </Box>
          </Slide>
        </Box>

        <Box className="features-types-container">
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img
                src="icon-financial-tools.svg"
                width={"140px"}
                alt="tools-icon"
              />
              <h2 className="features-types-headers">
                Next-Level Trading Tools
              </h2>
              <span className="features-types-spans">
                Unlock powerful trading tools designed to enhance
                decision-making, reduce risk, and amplify your earning
                potential.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-support.svg" width={"140px"} alt="support-icon" />
              <h2 className="features-types-headers">24/5 Dedicated Support</h2>
              <span className="features-types-spans">
                Our customer support team is always available through chat,
                email, or phone to assist with any inquiries, ensuring you're
                never left in the dark.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-shield.svg" width={"140px"} alt="shield-icon" />
              <h2 className="features-types-headers">Uncompromised Security</h2>
              <span className="features-types-spans">
                Protecting your privacy and assets is our top priority. Our
                robust security systems monitor your account round-the-clock,
                guarding against unauthorized access.
              </span>
            </Box>
          </Slide>
        </Box>
      </Box>
    </>
  );
};

export default FeaturesPage;
