import { Box } from "@material-ui/core";
import React from "react";
import "./index.scss";

function RiskDisclaimerPage() {
  return (
    <Box className="risk-policy-page-container">
      <Box>
        <h1 className="risk-policy-page-header">Risk Disclaimer Policy</h1>
      </Box>
      <Box className="risk-policy-page-textBox">
        <span className="risk-policy-page-span">
          This notice provides you with information about the risks associated
          with investment products, which you may invest in through services
          provided to you by Cronos AI. 1. GENERALITIES 1.1. This Risk
          Disclosure Notice forms part of the Terms & Conditions published on
          our website. 1.2. You are considering dealing using the Cronos AI
          (hereafter the “Company”, "we", "our", "us") trading platform
          (“Trading Platform”) to trade in foreign exchange (‘Forex’). These
          instruments are high risk investments, which are not suitable for many
          investors. 1.3. This notice provides you with information about the
          risks associated with Forex, but it cannot explain all of the risks
          nor how such risks relate to your personal circumstances. If you are
          in doubt you should seek professional advice. 1.4. It is important
          that you fully understand the risks involved before deciding to enter
          into a trading relationship with us. If you choose to enter into a
          trading relationship with us, it is important that you remain aware of
          the risks involved, that you have adequate financial resources to bear
          such risks and that you monitor your positions carefully. 2. GLOSSARY
          2.1. FOREX - When trading in foreign exchange, the investor speculates
          in the development of the price of one currency relative to another,
          where one is sold and the other is purchased. By way of example, an
          investor may sell British pounds (GBP) against the US dollar (USD) if
          he expects that the USD will increase relative to the GBP. Foreign
          exchange is traded as a margin product, which means that you can
          invest more money than is available in your account by borrowing money
          from The Company. 3. RISK DISCLOSURE NOTICE 3.1. Forex transaction are
          margin traded, allowing you to take a larger position than you would
          otherwise be able to based on your funds with The Company, a
          relatively small negative or positive movement in the underlying
          instrument can have a significant effect on your investment. Forex
          trading therefore involves a relatively high level of risk. This makes
          the potential gain quite high, even if the deposit is relatively
          small. If your total exposure on margin trades exceeds your deposit,
          you risk losing more than your deposit. 3.2. While Forex Transactions
          sometimes offer opportunities for high profits, they at the same time
          bear a high risk of losses since small variations in the prices can
          lead to a considerable loss. In other words, the greater the leverage
          effect, the greater the chance of gain and the risk of loss. The
          Client should use the leverage effect that is suitable to him. The
          Company does not examine whether the leverage effect used by the
          Client is suitable or recommended in view of the Client’s situation.
          3.3. The Client may wish to increase the Forex Margin very quickly in
          order to maintain his Open Position and avoid it being automatically
          liquidated. However, fluctuations in prices are often so rapid that
          the Client’s Open Position will be liquidated automatically without
          the Client having time to increase the Forex Margin. The Client also
          understands that a reduction in the leverage effect may lead to the
          automatic liquidation of his Open Positions. 4. POTENTIAL RISKS 4.1.
          You cannot lose more than your trading account balance. 4.2. Before
          you open a trade with us, we require you to lodge money with us as
          Initial Margin and, in order to keep a transaction open, you must
          ensure that the amount in your Trading Account exceeds the Maintenance
          Margin. The Initial Margin will differ between Instruments and the
          amounts will be indicated on the trading platform. This means that you
          will be trading using "leverage" or "gearing" and this can work for or
          against you; a small price movement in your favour can result in a
          high return on the Initial Margin placed for the trade, but a small
          price movement against you may result in substantial losses. 4.3. We
          will further require you to ensure that the amount in your Trading
          Account exceeds the Maintenance Margin in order to keep a Transaction
          open. Therefore, if our price moves against you, you may need to
          provide us with substantial additional Margin, at short notice, to
          maintain your open trades. If you do not do this, we will be entitled
          to close one or more or all of your trades. You will be responsible
          for any losses incurred. 4.4. You should also be aware that under our
          User Agreement we are entitled, at our sole discretion, to make a
          Margin Call. Under the User Agreement, you are required to satisfy any
          Margin Calls immediately, by wire transfer in the time prescribed by
          us. If you do not do this, we will be entitled to close one, or more,
          or all of your trades. 4.5. Unless you have taken steps to place an
          absolute limit on your losses (for example, by placing a Close at Loss
          or Close at Profit order on your account) it is possible for adverse
          market movements to result in the loss of the entire balance of your
          Trading Account. We offer a range of risk management tools to help you
          to manage this risk. 4.6. The exchange market, the bullion market and
          the markets for the other underlying assets of Forex Instruments are
          extremely volatile. The movements of these markets are unforeseeable.
          4.7. These markets may also experience periods of decreased liquidity
          or even periods of illiquidity. This liquidity risk may affect all the
          participants in the market or specifically the Company, in particular
          if there are changes in the liquidity provided by the Company’s
          counterparties. A lower liquidity may result in very rapid and hectic
          price movements, in wider spreads and/or in higher rejection rates.
          Forex Transactions aimed at excluding or limiting the risks arising
          from Open Positions, whether performed by the Client or by the
          Company, may therefore not be feasible or may only be so at a very
          unfavorable price. 5. OTHER RISKS 5.1. In exceptional circumstances or
          other undesirable situations, the market rules applicable to Forex
          Transactions may offer wide powers to the markets, clearing houses,
          bodies, organizations and companies that issued the said market rules
          which, if exercised, may considerably impact the Open Positions of the
          Client or his ability to carry out Forex Transactions. 5.2. Various
          events may arise over a weekend or, more generally, outside the
          Business Days, which may cause the markets to open at a significantly
          different price from where they closed. Orders cannot be executed
          outside the Business Days. This may cause considerable losses. Stop
          loss orders (as defined on the Company’s website or on the Forex
          Platforms) may be executed at prices significantly worse than the
          price desired by the Client. The Client’s open Orders may also not be
          cancelled outside the Business Days or outside the hours of operation
          of the Forex Platforms. 5.3. The insolvency of the Company or a
          custodian or counterparty used by the Company may result in the
          Client’s Open Positions being liquidated against his wishes or without
          the Client being consulted and without prior notice. 5.4. The risks
          associated with Forex Transactions are even higher if the said Forex
          Transactions are made on currencies or other underlying assets
          directly or indirectly connected with emerging markets. Indeed, many
          emerging markets lack a strong infrastructure. Telecommunications are
          generally poor, and Company’s and other financial systems are not
          always well developed, well regulated and well integrated. These
          countries may also have considerable external debt which could affect
          the proper functioning of their economies with a corresponding adverse
          impact on the performance of their markets. Tax regimes may be subject
          to the risk of a sudden imposition of arbitrary or onerous taxes,
          which could adversely affect investors. 5.5. Forex Transactions bear
          risks inherent to Internet and technology, as described in the General
          Terms and Conditions. Such risks include risks associated with
          latency, which the Client shall reduce by ensuring that his IT and
          mobile devices used for carrying out Forex transactions benefit from
          the fastest possible internet connectivity. 5.6. For any further
          information regarding the risks, the Client should refer to the
          brochure “Special risks linked to securities trading” available on the
          Company’s website, in particular the page related to the risk in
          investing in commodities. 6. CLIENT’S SITUATION 6.1. In the light of
          the risks described in this Forex Risk Disclosure Statement, the
          Client should carry out Forex Transactions only if he understands the
          nature of such Forex Transactions and the extent of his exposure to
          such risks, and if such Forex transactions are suitable for him. Forex
          Transactions are not suitable for many members of the public. 6.2. The
          Client undertakes to analyze his personal (in particular financial and
          tax) situation carefully before trading in Forex Instruments. The
          Client confirms that he has the necessary financial resources for all
          the Forex Transactions that he carries out or Orders to be carried
          out. The Client will only invest assets that he can afford to lose
          without having to change his standard of living, and the Client will
          cease trading in Forex Instruments if his personal situation no longer
          permits it. The Client understands that only assets that are not
          required for meeting the current expenses of his household and that
          are proportionate to his income and other assets should be placed at
          risk by Forex Transactions. The Forex Margin may be considered by the
          Company as “risk capital”. The Client understands that he should not
          carry out Forex Transactions if he is seeking a regular or a safe
          return. 6.3. The Client is solely responsible for deciding whether the
          Forex Transactions that he carries out are suitable in view of his
          personal (in particular financial and tax) situation, his investment
          objectives and other relevant circumstances. The Client further agrees
          that Forex Transactions is certainly not suitable for retirement
          funds. 6.4. In case of doubt, the Client should seek independent
          financial advice. 7. MANAGEMENT AND MONITORING OF OPEN POSITIONS 7.1.
          The Client is solely responsible for the management and monitoring of
          his Open Positions and open Orders. 7.2. In order to limit the extent
          of the risks, the Client may want to consider using different types of
          orders, such as Stop orders, Trailing Stop orders, One Cancels the
          Other (OCO) orders, If Done orders or If Done One Cancels the Other
          orders, as defined on the Company’s website or on the Forex Platforms.
          The Client acknowledges that placing such Orders may not necessarily
          guarantee limitation of the risk since, in certain market conditions,
          such Orders may not be executed. Indeed, depending on the
          circumstances, such as the liquidity available on the market, the
          Company will not be able to execute such Orders at the price the
          Client desires, and the Company shall not be liable for that. The
          Client remains responsible for any Forex Transaction executed at
          prices that differ from his Order. 7.3. The Client acknowledges that
          he shall frequently consult his Account, and in particular continually
          monitor the Forex Margin when he has one or several Open Positions or
          open Orders in his Account. 7.4. The Company has no obligation to
          cease entering into Forex Transactions when the Client suffers losses
          and/or the assets on the account decrease, even substantially. 8.
          CLIENT’S CONFIRMATIONS 8.1. The Client acknowledges and understands
          that trading in Forex Instruments is highly speculative, involves an
          extreme degree of risk and is generally suitable only for persons who
          can assume and sustain a risk of loss in excess of their Forex Margin.
          8.2. The Client acknowledges and understands all the risks associated
          with Forex Transactions, in particular the risk resulting from the use
          of a significant leverage effect, the volatility of the markets, the
          liquidity risk, the legal risks resulting, in particular, from the
          market rules applicable to Forex Transactions, the technology risks
          and any other risks that may lead to a loss or any other Damage. The
          Client confirms that he is willing to assume these risks. 8.3. The
          Client acknowledges that he has read and understood the General Terms
          and Conditions and the Special Terms and Conditions for Forex, as well
          as the information contained in the documents to which these Special
          Terms and Conditions for Forex refer, and in particular the Company’s
          website, the Trading Rules and the various prospectuses, fact sheets
          and other information sheets available on the Company’s website or on
          any Forex Platform. 8.4. The Client in particular confirms that he has
          understood the explanations about any restrictions to use Forex
          Platforms, the leverage effect and the modification of the maximum
          leverage effect, the Required Margin and the Automatic Liquidation
          System, as explained in the Special Terms and Conditions for Forex and
          other documents to which the Special Terms and Conditions for Forex
          refer. The Client also confirms that he has understood and accepts the
          role of the Company within the Forex Transactions and the risks and
          conflicts of interest related thereto. 8.5. The Client acknowledges
          and accepts that the Company is entitled to liquidate his Open
          Positions that are not adequately margined, and the Client will be
          liable for all losses as a result of such liquidation. The Client
          acknowledges that the Company reserves the right to change the
          Liquidation Percentage at its sole discretion. 8.6. The Client
          confirms that neither the Company nor its directors, managers,
          officers, employees, agents and other representatives guaranteed or
          guarantee to the Client that Forex Transactions will generate profits
          for the Client. Moreover, past yields and profits are no indication of
          future performance. 8.7. The Client confirms that the Forex
          Transactions he will carry out are suitable for him.
        </span>
      </Box>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Box>
  );
}

export default RiskDisclaimerPage;
