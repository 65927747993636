import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import "./index.scss";

const FaqPage = () => {
  return (
    <>
      <Box
        style={{
          marginTop: window.location.href.includes("faq") ? "3rem" : "0",
        }}
        className="faq-page-container"
      >
        <Box className="faq-page-textBox">
          <h3 className="faq-page-h3">Frequently Asked Questions</h3>
          <Box className="faq-page-span">
            <span>
              We are constantly evolving to provide you with the best trading
              experience possible. Here are some of the most common questions
              about our platform.
            </span>
          </Box>
        </Box>
        <Box className="faq-types-container-main">
          <Box className="faq-types-container">
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore style={{ color: "black" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    Are my funds safe, encrypted and protected?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    We prioritize the security of your funds. Customer funds are
                    held in segregated accounts under CFTC 1.25 regulations.
                    While we provide strong safety measures, it's important to
                    note that no system can guarantee 100% protection against
                    fraud or misconduct. For more detailed information on our
                    practices, please visit the{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        fontVariant: "petite-caps",
                        color: "white",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                      onClick={() =>
                        window.open(
                          "https://www.cftc.gov/sites/default/files/sites/default/files/idc/groups/public/%40requestsandactions/documents/ifdocs/08%20-%20FCM%20Webpage%20Update%20-%20August%202019.pdf"
                        )
                      }
                    >
                      CLICK HERE
                    </span>
                    to check the Financial Data for FCMs.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What can I trade assets on Cronos AI?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    At Cronos AI, you can trade a wide range of financial
                    instruments, including 60+ currency pairs, commodities,
                    equity indices, and cryptocurrencies.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I withdraw funds out of my account?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Withdrawing funds is easy. Simply log into your account, go
                    to the "Wallet" section, select "Withdraw", and complete the
                    withdrawal form. Once you submit, your funds will be
                    processed promptly.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I make a deposit?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    To deposit funds, log into your account, navigate to
                    "Wallet" and select "Deposit". From there, choose your
                    deposit method and enter the amount. Follow the steps
                    depending on your chosen payment method.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box className="faq-types-container">
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What withdrawal methods are available?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    You can withdraw funds via the following methods:
                    <br></br>• Bank Transfer<br></br>• Cryptocurrencies
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What deposit methods are available?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    You can deposit funds using the following methods:
                    <br></br>• Bank Transfer<br></br>• Debit/Credit Card (Visa
                    or MasterCard)<br></br>• Bitcoin
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How can I contact Cronos AI support?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    If you need assistance, Cronos AI offers customer support in
                    several languages. You can contact us via email, phone, or
                    live chat. To get in touch, please{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        fontVariant: "petite-caps",
                        color: "red",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                      onClick={() =>
                        window.open("https://cronos-ai.com/contact-us")
                      }
                    >
                      CLICK HERE
                    </span>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I know I have account updates?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    You will receive daily and monthly statements through your
                    secure online portal. Additionally, you’ll get account
                    updates via phone or email to keep you informed of any
                    important changes.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FaqPage;
