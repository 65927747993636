import { Box } from "@material-ui/core";
import React from "react";
import "./index.scss";

function RefundPolicyPage() {
  return (
    <Box className="refund-policy-page-container">
      <Box>
        <h1 className="refund-policy-page-header">Refund Policy</h1>
      </Box>
      <Box className="refund-policy-page-textBox">
        <span className="refund-policy-page-span">
          Cronos AI (the “Company”) applies certain rules and guidelines
          relating to the movement of your money between us and you and which
          will be applicable to the withdrawal of money by you to your account
          with the Company. Withdrawals 1. Withdrawals are processed subject to
          providing the Company with written request of withdrawal. 2. The
          Company will only send deposited funds to the credit card of User
          which was previously verified through which the User has made the
          deposit. The Company will not send funds to expired cards. Withdrawals
          of any profits generated by the User will be transferred to User’s
          bank account subject to User submitting the Company with accurate and
          complete bank account information. 3. The Company will not send funds
          back to an unverified source, including card or bank account. 4. The
          minimum amount for withdrawals via a wire transfer is 500 EUR/GBP. 5.
          The minimum withdraw request for gained profits on Cronos AI platform
          is 500 Units and they are paid via bank wire transfer. 6. The Company
          retains the right to allocate a withdrawal between several of your
          verified cards as it deems necessary until all withdrawal has been
          completed. 7. Company will make efforts to process withdrawal requests
          within 5-10 business days. However, depending on the withdrawal
          method, settlement of the funds will then be carried out by the
          Company’s bank or card processor and the time it takes may vary
          significantly from one use to another.
        </span>
      </Box>
    </Box>
  );
}

export default RefundPolicyPage;
