import { Box, Button } from "@material-ui/core";
import "./index.scss";
import { Slide } from "react-awesome-reveal";
import {
  AssignmentIndOutlined,
  AttachMoneyOutlined,
  VerifiedUserOutlined,
} from "@material-ui/icons";
import { DocumentScannerOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";

const HowItWorksPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        style={{
          marginTop: window.location.href.includes("how-it-works")
            ? "3rem"
            : "0",
        }}
        className="how-it-works-page-container"
      >
        <Box className="how-it-works-page-textBox">
          <h3 className="how-it-works-page-h3">How It Works</h3>
          <Box className="how-it-works-page-span">
            <h5>Getting started with Cronos AI is fast and straightforward:</h5>
          </Box>
        </Box>

        {/* Step 1: Enter Personal Details */}
        <Slide
          duration={2000}
          style={{ alignSelf: window.innerWidth <= 658 ? "center" : "start" }}
          className="personal-details-container"
        >
          <Box className="how-it-works-types-single-container">
            <AssignmentIndOutlined
              style={{ color: "#1199FA", width: "80px", height: "80px" }}
            />
            <h2 className="how-it-works-types-headers">
              Step 1: Fill In Personal Details
            </h2>
            <span className="how-it-works-types-spans">
              Complete your profile by entering basic personal information.{" "}
              <br />
              The process is quick and takes just a few minutes.
            </span>
          </Box>
        </Slide>

        {/* Step 2: Verify Identity */}
        <Slide duration={2000} className="verify-account-container">
          <Box className="how-it-works-types-single-container">
            <VerifiedUserOutlined
              style={{ color: "#1199FA", width: "80px", height: "80px" }}
            />
            <h2 className="how-it-works-types-headers">
              Step 2: Verify Your Identity
            </h2>
            <span className="how-it-works-types-spans">
              To ensure a secure experience, we’ll need to verify your identity.{" "}
              <br />
              This can be done easily by submitting three forms of ID.
            </span>
          </Box>
        </Slide>

        {/* Step 3: Fund Account and Start Trading */}
        <Slide
          duration={2000}
          style={{ alignSelf: window.innerWidth <= 658 ? "center" : "end" }}
        >
          <Box className="how-it-works-types-single-container">
            <AttachMoneyOutlined
              fontSize="large"
              style={{ color: "#1199FA", width: "80px", height: "80px" }}
            />
            <Button
              className="how-it-works-types-headers"
              style={{
                textShadow: "none",
                color: "black",
                fontWeight: "bold",
                fontSize: "1.2rem",
                backgroundColor: "#1199FA",
                letterSpacing: "0.5px",
                fontFamily: '"Tajawal", sans-serif',
              }}
              sx={{
                my: 2,
                color: "white",
                fontWeight: "bold",
                display: "block",
              }}
              // onClick={() => navigate("/sign-up")}
            >
              Begin Your Trading Journey
            </Button>

            <span className="how-it-works-types-spans">
              Once your account is funded, you’ll have access to over 250+
              financial products to trade. The world of trading is at your
              fingertips.
            </span>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default HowItWorksPage;
