import { Box, Button } from "@material-ui/core";
import { useNavigate } from "react-router";

import "./index.scss";
import { Slide } from "react-awesome-reveal";

const ToolsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        style={{ paddingBottom: "5%" }}
        className={
          window.location.href.includes("tools")
            ? "tools-page-container-landing"
            : "tools-page-container"
        }
      >
        <Slide duration={2000}>
          <Box className="tools-types-container">
            <Box className="tools-types-risk-img">
              <img src="icon-zero-commission.svg" alt="zero commission icon" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">
                Trade with Zero Commissions
              </h1>
              <span className="tools-types-spans">
                Experience seamless global trading at Cronos AI—where you can
                engage with the markets without worrying about commission fees.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                Zero commission applies exclusively to Cronos AI clients and
                excludes short or leveraged stock trades. While brokerage fees
                are waived on trade execution, other charges may still apply.
              </span>
              <br></br>
              <br></br>
              {/* <div className="button-wrap">
                <Button
                  variant="outlined"
                  color="inherit"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Get Started
                </Button>
              </div> */}
            </Box>
          </Box>
        </Slide>

        <Slide duration={2000} direction="right">
          <Box className="tools-types-container-2">
            <Box style={{ marginTop: "30px" }} className="tools-types-risk-img">
              <img src="icon-risk-management.svg" alt="risk management icon" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">
                Smart Risk Management Tools
              </h1>
              <span className="tools-types-spans">
                Maximize your trading potential with a suite of advanced risk
                management features that include tailored account types, fast
                trade execution, and innovative software tools.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                Leverage powerful tools like Stop Loss and Take Profit to
                automatically manage your risk and safeguard your profits.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                Investing always involves risk. With Cronos AI’s Risk Management
                Tools and personalized guidance from your financial analyst, you
                can take a strategic approach to optimize your trades.
              </span>
              <br></br>
              <br></br>

              {/* <div className="button-wrap">
                <Button
                  color="inherit"
                  variant="outlined"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div> */}
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default ToolsPage;
