import React from "react";
import { Fade, Roll, Slide } from "react-awesome-reveal";
// import Pulse from "react-reveal/Pulse";
import FaqPage from "../faqPage";
import FeaturesPage from "../featuresPage";
import PlatformPage from "../platformPage";
import ToolsPage from "../toolsPage";
import Particles from "react-tsparticles";
import AboutUsPage from "../aboutUsPage";

import "./index.scss";
import Footer from "../../components/footer";
import OurLocationsPage from "../ourLocationsPage";
import { Button, Zoom } from "@material-ui/core";

import { useNavigate } from "react-router";
import InfoPage from "../infoPage";
import HowItWorksPage from "../howItWorks";

function LandingPage() {
  const navigate = useNavigate();

  return (
    <div>
      {/* Particles background */}
      <Particles
        id="tsparticles"
        options={{
          background: {
            color: {
              value: "#000000",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 1,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.1,
              },
            },
          },
          particles: {
            color: {
              value: "#1199FA",
            },
            links: {
              color: "#1199FA",
              distance: 150,
              enable: true,
              opacity: 0.9,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 1,
              straight: false,
              bounce: false,
            },
            number: {
              density: {
                enable: true,
                area: 1500,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "triangle",
            },
            size: {
              random: true,
              value: 15,
            },
          },
          detectRetina: false,
        }}
      />

      {/* Main Landing Page Content */}
      <div className="div-container">
        <div className="inner-div-container">
          <Slide duration={2000}>
            <div className="inner-div-welcome-container">
              <h1 className="landingPageFirstHeader">
                Build Your Own Asset Portfolio Today
              </h1>
              <h3 className="landingPageCSFC">
                Begin your crypto experience by clicking below.
              </h3>
              <span className="landingPageSpan">
                Cronos AI offers a groundbreaking, multi-asset trading platform
                that enables effortless transitions between markets. Trade at
                your convenience with one unified app—single login, multiple
                markets.
              </span>
              <div className="pulse-container">
                <span
                  className="pulse-button"
                  onClick={() => navigate("/login")}
                >
                  Start Trading Now
                </span>
              </div>
            </div>
          </Slide>

          <img
            style={{ position: "absolute", top: "10%", right: "5%" }}
            className="laptop-image-landing"
            src="metatrader6.webp"
            width="600px"
            alt="asset-png"
          />
        </div>
      </div>

      {/* Additional Sections */}
      <FeaturesPage />
      <HowItWorksPage />
      <PlatformPage />
      <ToolsPage />
      {/* <InfoPage /> */}
      <FaqPage />
      {/* <AboutUsPage /> */}
      {/* <OurLocationsPage /> */}

      <Footer />
    </div>
  );
}

export default LandingPage;
