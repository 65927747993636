import React, { useState } from "react";
import "./edit-user.scss";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextareaAutosize,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";

import { useDispatch, useSelector } from "react-redux";
import { userServices } from "../../../../services/userServices";
import { getAllRegisteredUsers } from "../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import { CreatePositionFields } from "./components/createPosition";
import { SimulatedWithdrawHistory } from "./components/simulatedWithdrawHistory";
import { OpenRealTimePosition } from "./components/openRealTimePosition";
import { SimulatedTransactionHistory } from "./components/simulateTransaction";
import {
  getAllWithdrawHistory,
  getUserWithdrawHistory,
} from "../../../../redux/withdrawHistory/withdrawHistorySlice";
import UserOrders from "./components/userOrders";
import { getAllTransactions } from "../../../../redux/transactions/transactionsSlice";
import CurrentOpenedOrder from "./components/currentOpenOrder";
import { getUserOrders } from "../../../../redux/orders/orderSlice";
import {
  getUserAvailableClients,
  getUserBalance,
  login,
} from "../../../../redux/users/userSlice";
import { depositorService } from "../../../../services/depositorService";
import ColumnGroupingTableWithdraws from "../dashboard/components/withdrawAdminDashboard";
import ColumnGroupingTableTransactions from "../dashboard/components/transactionsAdminDashboard";
import LoginHistoryTable from "./loginHistoryTable";
import UserUploadedDocuments from "./userUploadedDocuments";
import Tab from "@mui/material/Tab";

const EditUser = ({
  user,
  setOpenModal,
  fetchUsersPayload,
  setShowLoader,
  showLoader,
}) => {
  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  const [editButtonOptions, setEditButtonOptions] = React.useState("");
  const users = useSelector((state) => state.users.users);
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = React.useState("");
  const [areYouSure, setAreYouSure] = React.useState(false);
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);
  const [userDetails, setUserDetails] = React.useState({
    job: user.job,
    city: user.city,
    email: user.email,
    phone: user.phone,
    gender: user.gender,
    equity: user.equity,
    profit: user.profit,
    status: user.status,
    country: user.country,
    lastName: user.lastName,
    postCode: user.postCode,
    ballance: user.ballance,
    firstName: user.firstName,
    dayOfBirth: user.dayOfBirth,
    freeMargin: user.freeMargin,
    yearOfBirth: user.yearOfBirth,
    monthOfBirth: user.monthOfBirth,
    streetAddress: user.streetAddress,
    verifiedAddress: user.verifiedAddress,
    verifiedFunding: user.verifiedFunding,
    verifiedPassport: user.verifiedPassport,
    comment: user.comment,
    agent: user.agent,
    type: user.type,
    achievement: user.achievement,
    notes: user.notes,
    underInvestigation: user.underInvestigation,
    loginForbidden: user.loginForbidden,
    currencySymbol: user.currencySymbol,
  });

  const [leadNotes, setLeadNotes] = React.useState({
    note: "",
  });
  const handleCreateNote = (e) => {
    const value = e.target.value;
    setLeadNotes({
      ...leadNotes,
      [e.target.name]: value,
    });
  };
  React.useEffect(() => {
    // dispatch(getAllWithdrawHistory());
    // dispatch(getAllTransactions());
    dispatch(getUserWithdrawHistory(user?.id));
    dispatch(getUserBalance(user?.id));
    dispatch(getUserOrders(user?.id));
    dispatch(getUserAvailableClients(reduxUser?.id));
  }, [dispatch]);
  const genders = ["male", "female"];
  const status = [
    "DIALING",
    "NO ANSWER",
    "RET - NO ANSWER",
    "RET - NOT INTERESTED",
    "RET - CALL BACK",
    "RET - TRASH",
    "RET - LOW PRIORITY",
    "HANG UP",
    "CALL BACK",
    "DVM",
    "FTD",
    "NEW LEAD",
    "DEPOSIT DECLINE",
    "INVALID",
    "UNDER AGED",
    "NOT INTERESTED",
    "HIGH PRORITY",
    "MEDIUM PRORITY",
    "LOW PRORITY",
    "UPSELL",
    "LANGUAGE ISSUES",
    "RECOVERY",
    "TRASH",
    "TRY AGAIN",
    "REASSIGNED",
    "NEW",
    "DUPLICATE",
    "RETENTION",
    "WRONG DETAILS",
  ];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setUserDetails({
      ...userDetails,
      [e.target.name]: value,
    });
    setDisableSaveButton(false);
  };

  const handleLeadStatusChange = (e) => {
    const value = e.target.value;
    setUserDetails({
      ...userDetails,
      [e.target.name]: value,
    });
    userServices.changeUserStatus(user.id, {
      status: value,
    });
  };

  const handlePutAccountUnderInvestigation = async () => {
    await userServices
      .changeAccountToInvestigationMode(user?.id, {
        underInvestigation: !userDetails.underInvestigation,
      })
      .then(() => {
        setUserDetails({
          ...userDetails,
          underInvestigation: !userDetails.underInvestigation,
        });
      });
  };

  const handlePutAccountLoginForbiddenStatus = async () => {
    await userServices
      .changeAccountToLoginForbiddenStatus(user?.id, {
        loginForbidden: !userDetails.loginForbidden,
      })
      .then(() => {
        setUserDetails({
          ...userDetails,
          loginForbidden: !userDetails.loginForbidden,
        });
      });
  };

  const userNotesWithoutPastComments = userDetails?.notes?.toReversed() || [];
  const userNotes = !!userDetails?.comment
    ? userNotesWithoutPastComments?.concat([
        {
          note: userDetails?.comment,
        },
      ])
    : userNotesWithoutPastComments;
  const [showAddNewComment, setShowAddNewComment] = useState(false);
  const handleSaveNewComment = () => {
    userServices
      .addNoteByLeadId(user.id, {
        notes: leadNotes,
        createdAt: new Date().toISOString(),
      })
      .then(() => {
        console.log(leadNotes);
        setUserDetails({
          ...userDetails,
          ["notes"]: [
            ...userNotes,
            { ...leadNotes, createdAt: new Date().toISOString() },
          ],
        });
        setLeadNotes({
          note: "",
        });
        setShowAddNewComment(false);
      });
  };
  const handleOnSave = () => {
    userServices
      .updateUser(user.id, {
        job: userDetails.job,
        city: userDetails.city,
        email: userDetails.email,
        phone: userDetails.phone,
        gender: userDetails.gender,
        equity: userDetails.equity,
        profit: userDetails.profit,
        country: userDetails.country,
        lastName: userDetails.lastName,
        postCode: userDetails.postCode,
        ballance: userDetails.ballance,
        firstName: userDetails.firstName,
        dayOfBirth: userDetails.dayOfBirth,
        freeMargin: userDetails.freeMargin,
        yearOfBirth: userDetails.yearOfBirth,
        monthOfBirth: userDetails.monthOfBirth,
        streetAddress: userDetails.streetAddress,
        verifiedAddress: userDetails.verifiedAddress,
        verifiedFunding: userDetails.verifiedFunding,
        verifiedPassport: userDetails.verifiedPassport,
        comment: userDetails.comment,
        agent: userDetails.agent,
        type: userDetails.type,
        achievement: userDetails.achievement,
        currencySymbol: userDetails.currencySymbol,
      })
      .then(() => {
        setDisableSaveButton(true);
        dispatch(getAllRegisteredUsers(fetchUsersPayload));
      });
  };

  const handleChangeTab = (event, newValue) => {
    setEditButtonOptions(newValue);
  };

  const handleLoginAdmin = async () => {
    const userData = await userServices.loginUserAdmin({
      userId: user.id,
    });
    if (userData.success) {
      await localStorage.setItem("accessToken", userData.accessToken);
      await dispatch(login(userData));
      window.location.replace("/trade");
    } else {
      console.log("Error logging in!");
    }
  };
  return (
    <div className="edit-user-wrap">
      <div className="edit-user-modal">
        <div className="edit-user-modal-info">
          <div className="edit-user-top">
            <h2
              style={{
                color: "white",
                padding: "10px",
                fontSize: "1.8rem",
                textAlign: "center",
              }}
            >
              Edit User
            </h2>
            <CloseIcon
              style={{ cursor: "pointer", color: "white" }}
              onClick={() => {
                // dispatch(getUserBalance(user.id));
                setOpenModal(false);
                dispatch(getAllRegisteredUsers(fetchUsersPayload));
              }}
            />
          </div>
          {/* 1 ROW */}
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <TextField
              className="edit-user-textField"
              value={userDetails.firstName}
              label="First Name"
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              name="firstName"
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Last Name"
              value={userDetails.lastName}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              name="lastName"
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />

            <TextField
              className="edit-user-textField"
              label="E-mail"
              value={userDetails.email}
              variant="outlined"
              name="email"
              style={{ margin: "12px 2px", width: "150px" }}
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />

            <TextField
              className="edit-user-textField"
              label="Phone Number"
              variant="outlined"
              name="phone"
              style={{ margin: "12px 2px", width: "150px" }}
              value={userDetails.phone}
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
          </div>
          {/* 2 ROW */}
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <TextField
              className="edit-user-textField"
              label="Country"
              name="country"
              value={userDetails.country}
              style={{ margin: "12px 2px", width: "150px" }}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />

            <TextField
              className="edit-user-textField"
              label="City"
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              name="city"
              onChange={(e) => handleInputChange(e)}
              value={userDetails.city}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Street Address"
              name="streetAddress"
              value={userDetails.streetAddress}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />

            <TextField
              className="edit-user-textField"
              label="Post Code"
              variant="outlined"
              name="postCode"
              style={{ margin: "12px 2px", width: "150px" }}
              onChange={(e) => handleInputChange(e)}
              value={userDetails.postCode}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
          </div>
          {/* 3 ROW */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <TextField
              className="edit-user-textField"
              value={userDetails.dayOfBirth}
              label="Day Of Birth"
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              name="dayOfBirth"
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Month Of Birth"
              value={userDetails.monthOfBirth}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              name="monthOfBirth"
              style={{ margin: "12px 2px", width: "150px" }}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Year Of Birth"
              value={userDetails.yearOfBirth}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              name="yearOfBirth"
              style={{ margin: "12px 2px", width: "150px" }}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={userDetails.job}
              label="Job"
              variant="outlined"
              name="job"
              style={{ margin: "12px 2px", width: "150px" }}
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
          </div>
          {/* 4 ROW */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "fake" ||
              reduxUser?.role === "retention") && (
              <TextField
                className="edit-user-textField"
                label="Balance"
                variant="outlined"
                name="ballance"
                style={{ margin: "12px 2px", width: "150px" }}
                onChange={(e) => handleInputChange(e)}
                value={userDetails.ballance}
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "fake" ||
              reduxUser?.role === "retention") && (
              <FormControl>
                <InputLabel style={{ color: "white", marginTop: "3%" }}>
                  Gender
                </InputLabel>
                <Select
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    height: "35px",
                    margin: "12px 2px",
                    borderRadius: "2px",
                    width: "150px",
                    backgroundColor: "#1F2A40",
                  }}
                  value={userDetails.gender}
                  label="Verified Address"
                  name="gender"
                  onChange={(e) => handleInputChange(e)}
                >
                  {genders.map((g) => (
                    <MenuItem value={g}>{g}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "fake" ||
              reduxUser?.role === "retention") && (
              <FormControl>
                <InputLabel style={{ color: "white", marginTop: "3%" }}>
                  Account Status
                </InputLabel>
                <Select
                  value={userDetails.status}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    height: "35px",
                    margin: "12px 2px",
                    borderRadius: "2px",
                    width: "150px",
                    backgroundColor: "#1F2A40",
                  }}
                  label="Account Status"
                  name="status"
                  onChange={(e) => handleLeadStatusChange(e)}
                >
                  {status.map((s) => (
                    <MenuItem value={s}>{s}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "fake" ||
              reduxUser?.role === "retention") && (
              <FormControl>
                <InputLabel style={{ color: "white", marginTop: "3%" }}>
                  Currency
                </InputLabel>
                <Select
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    height: "35px",
                    margin: "12px 2px",
                    borderRadius: "2px",
                    backgroundColor: "#1F2A40",
                    width: "150px",
                  }}
                  value={userDetails?.currencySymbol}
                  label="Currency"
                  name="currencySymbol"
                  onChange={(e) => handleInputChange(e)}
                >
                  {["$", "€", "kr"].map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          <div className="edit-user-buttons-group">
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "fake" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "30px",
                  background: "#3da58a",
                  fontSize: "0.6rem",
                  fontWeight: "bold",
                  fontSize: "0.6rem",
                }}
                size="medium"
                variant="contained"
                onClick={handleLoginAdmin}
              >
                Login
              </Button>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "fake" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "30px",
                  background: "red",
                  fontSize: "0.6rem",
                  fontWeight: "bold",
                  alignSelf: "center",
                }}
                variant="contained"
                size="medium"
                color={!userDetails.underInvestigation ? "error" : "success"}
                onClick={() => handlePutAccountUnderInvestigation()}
              >
                {!userDetails.underInvestigation
                  ? "Restrict Account"
                  : "Allow Account"}
              </Button>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "fake" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "30px",
                  fontSize: "0.6rem",
                  background: "red",
                  fontWeight: "bold",
                  alignSelf: "center",
                }}
                size="medium"
                variant="contained"
                color={!userDetails.loginForbidden ? "error" : "success"}
                onClick={() => handlePutAccountLoginForbiddenStatus()}
              >
                {!userDetails.loginForbidden
                  ? "Disable Logins"
                  : "Enable Logins"}
              </Button>
            )}
            {(reduxUser?.role === "admin" || reduxUser?.role === "fake") && (
              <Button
                style={{
                  width: "150px",
                  height: "30px",
                  fontWeight: "bold",
                  background: "red",
                  fontSize: "0.6rem",
                }}
                variant="contained"
                onClick={() => {
                  setAreYouSure(true);
                }}
              >
                Delete User
              </Button>
            )}
            {areYouSure && (
              <div
                className="areYouSureModal"
                style={{
                  width: "35%",
                  height: "35%",
                  inset: 0,
                  margin: "20% auto",
                }}
              >
                <h2 style={{ margin: "1rem auto" }}>Are you sure?</h2>
                <h3>The user will be permanently terminated!</h3>
                <div className="areYouSureModalBtnContainer">
                  <Button
                    variant="contained"
                    color="success"
                    style={{ height: "30px", width: "100px", marginTop: "20%" }}
                    onClick={() => {
                      userServices.deleteUser(user.id).then(() => {
                        dispatch(getAllRegisteredUsers(fetchUsersPayload));

                        setAreYouSure(false);
                        setOpenModal(false);
                      });
                    }}
                  >
                    YES
                  </Button>
                  <Button
                    style={{ height: "30px", width: "100px", marginTop: "20%" }}
                    variant="contained"
                    color="error"
                    onClick={() => setAreYouSure(false)}
                  >
                    NO
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Tabs
              value={editButtonOptions}
              onChange={handleChangeTab}
              textColor="primary"
              indicatorColor="secondary"
              selectionFollowsFocus
            >
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="createPosition"
                label="Create position"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="openPosition"
                label="Open live order"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="simulatedTransaction"
                label="Simulate transaction"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="simulatedWithdraw"
                label="Simulate withdrawal"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="addNewComment"
                onClick={() => setShowAddNewComment((prev) => !prev)}
                label="Add comment"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="userOrders"
                label="Order history"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="userOpenedOrders"
                label="Current live orders"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="userTransactions"
                label="Transaction history"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="userWithdraws"
                label="Withdrawal history"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="commentsHistory"
                label="Comments"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="userLoginHistory"
                label="Login history"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="userUploadedDocuments"
                label="Uploaded documents"
              />
              <Tab
                style={{
                  width: "100px",
                  background: "#3DA58A",
                  color: "white",
                  fontWeight: "bold",
                  margin: "0.1%",
                }}
                value="resetPassword"
                label="Reset password"
              />
            </Tabs>
          </div>
          {editButtonOptions === "deleteUser" && (
            <div
              style={{
                textAlign: "center",
                color: "red",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              User {user.firstName + "" + user.lastName} has been deleted!
            </div>
          )}
          {editButtonOptions === "userOrders" && (
            <UserOrders
              user={user}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "userTransactions" && (
            <ColumnGroupingTableTransactions
              user={user}
              editButtonOptions={editButtonOptions}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "userWithdraws" && (
            <ColumnGroupingTableWithdraws
              user={user}
              editButtonOptions={editButtonOptions}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "userOpenedOrders" && (
            <CurrentOpenedOrder
              setShowLoader={setShowLoader}
              showLoader={showLoader}
              user={user}
              fetchUsersPayload={fetchUsersPayload}
              setEditButtonOptions={setEditButtonOptions}
              setOpenModal={setOpenModal}
            />
          )}
          {editButtonOptions === "userLoginHistory" && (
            <LoginHistoryTable
              user={user}
              editButtonOptions={editButtonOptions}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "userUploadedDocuments" && (
            <UserUploadedDocuments
              user={user}
              setDisableSaveButton={setDisableSaveButton}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              editButtonOptions={editButtonOptions}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "commentsHistory" &&
            (userNotes?.length > 0 ? (
              <TableContainer
                sx={{
                  marginBottom: "2rem",
                  minHeight: 250,
                  maxHeight: 450,
                  minWidth: 550,
                  margin: "auto",
                  maxWidth: 800,
                }}
              >
                <Table
                  sx={{
                    minWidth: 550,
                    maxWidth: 800,
                    boxShadow: "0px 0px 5px 5px",
                    borderTop: "1px solid black",
                  }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontSize: "1rem", fontWeight: "bold" }}
                        align="left"
                      >
                        Comment
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "1rem", fontWeight: "bold" }}
                        align="left"
                      >
                        Created at
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userNotes?.map((note, index) => (
                      <TableRow
                        key={note.note}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: "#1f2a40",
                        }}
                      >
                        <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                          {note.note}
                        </TableCell>
                        <TableCell sx={{ fontSize: "0.9rem" }} align="left">
                          {!!note.createdAt
                            ? new Date(note.createdAt).toLocaleString("sv-SE", {
                                timeZone: "Europe/Stockholm",
                              })
                            : "---"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <h3
                style={{
                  textAlign: "center",
                  color: "gray",
                  fontSize: "0.6rem",
                }}
              >
                Leave your first comment...
              </h3>
            ))}
          {editButtonOptions === "createPosition" && (
            <CreatePositionFields
              users={users}
              fetchUsersPayload={fetchUsersPayload}
              user={user}
              setOpenModal={setOpenModal}
              setUserDetails={setUserDetails}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "simulatedTransaction" && (
            <SimulatedTransactionHistory
              fetchUsersPayload={fetchUsersPayload}
              user={user}
              setOpenModal={setOpenModal}
              setUserDetails={setUserDetails}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "simulatedWithdraw" && (
            <SimulatedWithdrawHistory
              fetchUsersPayload={fetchUsersPayload}
              setOpenModal={setOpenModal}
              user={user}
              setUserDetails={setUserDetails}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "addNewComment" && showAddNewComment && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextareaAutosize
                className="edit-user-textField"
                label="Comment"
                variant="outlined"
                name="note"
                placeholder="Comment"
                maxRows={10}
                minRows={6}
                style={{
                  height: "96px",
                  minWidth: 550,
                  maxWidth: 800,
                  fontWeight: "bold",
                  color: "white",
                  fontFamily: "Montserrat",
                  backgroundColor: "#1F2A40",
                  marginBottom: "2rem",
                }}
                fullWidth
                onChange={(e) => handleCreateNote(e)}
                value={leadNotes.note}
                InputLabelProps={{
                  style: { color: "white", fontWeight: "bold" },
                }}
              />
              {!!leadNotes["note"] && (
                <Button
                  style={{
                    width: "120px",
                    height: "50px",
                    background: "yellow",
                    fontSize: "0.6rem",
                    fontWeight: "bold",
                    alignSelf: "center",
                    marginBottom: "1rem",
                  }}
                  variant="outlined"
                  onClick={handleSaveNewComment}
                >
                  Save comment
                </Button>
              )}
            </div>
          )}
          {editButtonOptions === "openPosition" && (
            <OpenRealTimePosition
              user={user}
              fetchUsersPayload={fetchUsersPayload}
              setEditButtonOptions={setEditButtonOptions}
              setOpenModal={setOpenModal}
              setUserDetails={setUserDetails}
            />
          )}
          {editButtonOptions === "resetPassword" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "0 auto",
              }}
            >
              <TextField
                className="edit-user-textField"
                label="New Password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                style={{ margin: "12px 2px", width: "150px" }}
                variant="outlined"
                name="newPassword"
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
              <Button
                color="success"
                variant="outlined"
                style={{
                  margin: "0 auto",
                  width: "150px",
                  marginBottom: "10%",
                }}
                onClick={() => {
                  userServices.changeUserPassword(user?.id, {
                    password: newPassword,
                  });
                  setEditButtonOptions("");
                }}
              >
                Save Password
              </Button>
            </div>
          )}
          <div className="edit-user-button-wrap">
            <Button
              disabled={disableSaveButton}
              style={{ width: "100px" }}
              color="success"
              variant="contained"
              onClick={() => handleOnSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
